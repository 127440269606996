export default theme => ({
	root: {
                '& img': {
                   paddingTop: 0,
                },
                '& .MuiTypography-h4':  {
                   paddingTop: '1rem',
                   paddingBottom: '1rem',
                   '@media (max-width: 960px)': {
                             padding: '0.5rem 1rem ',
                             fontSize: '1.25rem',
                     }
                },
                '& .MuiTypography-h6':  {
                   marginBottom: '1rem',
                                      '@media (max-width: 960px)': {
                          fontSize: '0.9rem',

                     }
                },
	
		'& .MuiGrid-item': {
			border: '0px solid',
			borderBottomWidth: 1,
			marginBottom: -1,
			borderColor: theme.palette.text.hint,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: 0,
			[theme.breakpoints.up('xs')]: {
				borderRightWidth: 1,
			}
		}
	}
})